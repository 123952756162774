<template>
  <b-card>
    <div>
      <!-- User Info: Input Fields -->
      <validation-observer ref="formStaff">
        <b-form @submit.prevent="handleSubmit">
          <!-- Header: Personal Info -->
          <div class="d-flex">
            <h4 class="mb-0">Thông tin chiến dịch</h4>
          </div>
          <b-row class="mt-1">
            <b-col>
              <validation-provider #default="{ errors }" name="code" rules="required">
                <b-form-group label="Mã chiến dịch" label-for="campaign-code">
                  <b-form-input id="campaign-code" v-model="campaign.code" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col>
              <validation-provider #default="{ errors }" name="name" rules="required">
                <b-form-group label="Tên chiến dịch" label-for="campaign-name">
                  <b-form-input id="campaign-name" v-model="campaign.name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <div class="d-flex mt-2">
            <h4 class="mb-0">Thông tin đội</h4>
          </div>
          <b-row class="mt-1">
            <b-col>
              <v-select
                v-model="teamSelected"
                multiple
                label="name"
                :reduce="(item) => item.id"
                :options="teamOptions"
                placeholder="Thêm đội"
                class="w-100"
              />
            </b-col>
          </b-row>

          <b-row class="mt-2 justify-content-center">
            <!-- Action Buttons -->
            <b-button
              variant="outline-secondary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: constRouter.CAMPAIGN.name }"
            >
              Hủy
            </b-button>
            <b-overlay :show="loading" block opacity="0.6" spinner-variant="primary">
              <b-button type="submit" variant="primary" block> Cập nhật </b-button>
            </b-overlay>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BFormRadioGroup,
  BCard,
  BAvatar,
  BMedia,
  BInputGroupAppend,
  BOverlay,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { avatarText } from '@core/utils/filter';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import constRouter from '@/constants/constRouter';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import vSelect from 'vue-select';
import {
  campaignCreate,
  campaignListTeam,
  campaignShow,
  campaignShowTeam,
  campaignUpdate,
} from '@/apis/apiCampaign';

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadioGroup,
    BCard,
    BAvatar,
    BMedia,
    BInputGroupAppend,

    flatPickr,

    ValidationProvider,
    ValidationObserver,

    vSelect,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const genderOptions = [
      { text: 'Nam', value: 'male' },
      { text: 'Nữ', value: 'female' },
    ];

    const avatar = ref(null);

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
      // eslint-disable-next-line no-param-reassign
      avatar.value = base64;
    });

    return {
      avatar,
      avatarText,
      genderOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      constRouter,
    };
  },
  data: () => ({
    required,
    loading: false,
    campaign: {
      code: undefined,
      name: undefined,
    },
    teamSelected: [],
    teamOptions: [],

    id: undefined,
  }),
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  created() {
    this.id = this.$route.params.id;

    this.fetchCampaignData();
    this.fetchTeams();
  },
  methods: {
    fetchCampaignData() {
      const campaignShowApi = campaignShow(this.id);
      const campaignShowTeamApi = campaignShowTeam(this.id);
      Promise.all([campaignShowApi, campaignShowTeamApi]).then((res) => {
        const campaign = res[0].data.data;
        const campaignTeam = res[1].data.data;
        console.log(campaign, campaignTeam);
        this.campaign = {
          code: campaign.code,
          name: campaign.name,
        };

        if (campaignTeam && campaignTeam.length > 0) {
          this.teamSelected = campaignTeam.map((item) => item.id);
        }
      });
    },
    fetchTeams() {
      campaignListTeam().then((res) => {
        this.teamOptions = res.data.data;
        console.log(this.teamOptions);
      });
    },
    handleSubmit() {
      const form = this.$refs.formStaff;
      form.validate().then((success) => {
        if (success) {
          this.loading = true;
          const { code, name } = this.campaign;
          const teams = this.teamSelected;

          const payload = {
            code,
            name,
            teams,
          };

          campaignUpdate(this.id, payload)
            .then((res) => {
              this.loading = false;
              const { data } = res.data;
              this.$router
                .push({ name: constRouter.CAMPAIGN.name, params: { id: data?.id } })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Sửa chiến dịch',
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      text: 'Sửa chiến dịch thành công!',
                    },
                  });
                });
            })
            .catch((err) => {
              this.loading = false;
              if (err.response.status === 422) {
                form.setErrors(err.response.data.errors);
              }
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sửa chiến dịch',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Sửa chiến dịch thất bại!',
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
